
import './Banner.css';
import React from 'react';
import { Pagination, Autoplay, Parallax} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // 基础样式
import 'swiper/css/navigation'; // 如果用到了 Navigation
import 'swiper/css/pagination'; // 如果用到了 Pagination
import 'swiper/css/scrollbar';

import { useEffect, useState } from "react";
import { supabase } from "./Supabase";

export interface SongBean {
    title: string
    image: string
}

export function Banner() {
    const [songList, setSongList] = useState<SongBean[]>([]);
    useEffect(() => {
        supabase.from("songs").select().then((res) => {
            const { data, error } = res;
            if (error) {
                return;
            }
            const curSongList = data?.map((item, index) => {
                return {
                    title: item.title as string,
                    image: item.image as string,
                };
            });
            setSongList(curSongList);
        });
    }, [])
    return (
        <div className="banner-container">
            <Swiper
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay, Parallax]}
                className="mySwiper"
            >
                {
                    songList.map((song, index) => (
                        <SwiperSlide key={index}>
                            <img src={song.image} />
                        </SwiperSlide>

                    ))
                }
                {/* <SwiperSlide>Slide 1</SwiperSlide>
                <SwiperSlide>Slide 2</SwiperSlide>
                <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
            </Swiper>
        </div>
    );
}