import React, { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.css';

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination } from 'swiper/modules';
import FlipNumbers from 'react-flip-numbers';
import { supabase } from "./Supabase";
import { Songs } from "./Songs";
import { Banner } from "./Banner";

import { SpeedInsights } from "@vercel/speed-insights/react"


function App() {
  const [number, setNumber] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const { data, count, error } = await supabase.from('likes').select('*', { head: true, count: 'exact' });
      setNumber(count ?? 0);
    }
    fetchData();
  });

  async function handleSupport() {
    setNumber(number + 1);
    await supabase
      .from('likes')
      .insert([{}])
  }


  return (
    <div className='appContainer'>
      <Banner></Banner>
      <div className='nameContainer'>
        云朵
      </div>
      <div className='bioContainer'>
        云朵，原名谢春芳，羌族，1987年2月7日出生于四川省阿坝藏族羌族自治州茂县，中国内地女歌手。
      </div>
      <div className="flip-numbers">
        <div className="countContainer">累计</div>
        {number.toString().split('').map((digit, _) => (
          <div className='flip-numbers-container' key={_}>
            <FlipNumbers
              width={18}
              height={18}
              color="#000"
              play
              numbers={digit}
            />
          </div>
        ))}
        <div className="supportContainer">人支持</div>
      </div>
      <div className='supportBtnContainer'>
        <div className='suppertBtn' onClick={handleSupport}>
          👍支持云朵
        </div>
      </div>
      <Songs></Songs>
      <SpeedInsights />
    </div>
  );
}

export default App;


// return (
//   <>
//     <Swiper
//       effect={'coverflow'}
//       grabCursor={true}
//       centeredSlides={true}
//       slidesPerView={'auto'}
//       coverflowEffect={{
//         rotate: 50,
//         stretch: 0,
//         depth: 100,
//         modifier: 1,
//         slideShadows: true,
//       }}
//       pagination={true}
//       modules={[EffectCoverflow, Pagination]}
//       className="mySwiper"
//     >
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
//       </SwiperSlide>
//     </Swiper>
//   </>
// );