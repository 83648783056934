import { useEffect, useState } from "react";
import { supabase } from "./Supabase";
import { Row, Col } from "antd";

import './Songs.css';

export interface SongBean {
    title: string
    image: string
}


export function Songs() {
    const [songList, setSongList] = useState<SongBean[]>([]);
    useEffect(() => {
        supabase.from("songs").select().then((res) => {
            const { data, error } = res;
            if (error) {
                return;
            }
            const curSongList = data?.map((item, index) => {
                return {
                    title: item.title as string,
                    image: item.image as string,
                };
            });
            setSongList(curSongList);
        });
    }, [])

    return (
        <div className="song-container">
            <div className="title-container">演唱作品</div>
            <div className="grid-container">
                {songList.slice(0, Math.min(6, songList.length)).map((song, index) => (
                    <div key={index} className="grid-item">
                        <img src={song.image} />
                        <h3>{song.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}